<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Historia Zamówień</b> w tym miejscu znajdują się Twoje zamówienia
        nagród w Programie Lojalnościowym
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Historia zamówień'">
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                ref="claimstable"
                responsive
                striped
                hover
                :items="orders.items"
                :fields="orders.fields"
                :filter="filter"
                :tbody-tr-class="rowClass"
                @row-clicked="getDetails"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mb-1"
                    @click="deleteOrder(row.item)"
                    v-if="row.item.status === 'ZŁOŻONE'"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>

    <template>
      <!-- details modal  -->
      <b-modal
        size="xl"
        scrollable
        ref="orderDetailModal"
        :title="order.fullNumber"
      >
        <div class="d-block text-center"></div>

        <b-container>
          <div class="row">
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Status:</b>
                    </div>
                    <div class="col-md-6">
                      <b-badge>{{ order.status }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Data zamówienia:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.date }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>E-mail:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.email }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Akronim:</b>
                    </div>
                    <div class="col-md-6">
                      <b-badge>{{ order.erpUser.acronym }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Imię nazwisko:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.person }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      <b>Telefon:</b>
                    </div>
                    <div class="col-md-6">
                      {{ order.erpUser.phone }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <hr />
          <div v-if="order.name.length > 0">
            <b-button v-b-toggle.collapse-delivery size="sm" variant="primary"
              >Dane dostawy</b-button
            >
            <b-collapse id="collapse-delivery" class="mt-2">
              <b-card>
                {{ order.name }} <br />
                {{ order.street }} <br />
                {{ order.postal }} {{ order.locality }}
              </b-card>
            </b-collapse>
            <hr />
          </div>
        </b-container>

        <b-table
          responsive
          striped
          bordered
          hover
          :fields="orderElements.fields"
          :items="orderElements.items"
        >
        </b-table>
        <div class="d-block text-right">
          <h2>
            <b-badge variant="warning"
              >koszt razem: {{ totalPointCost }} pkt</b-badge
            >
          </h2>
          <b-badge variant="secondary"
            >forma płatności: {{ order.paymentForm }}</b-badge
          >
        </div>
      </b-modal>
      <!-- end of details modal -->
    </template>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      state: "loading",
      filter: null,
      orders: {
        fields: [
          {
            key: "fullNumber",
            label: "Numer Zamówienia",
            sortable: true
          },
          {
            key: "date",
            label: "Data",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "paymentForm",
            label: "Forma Płatności",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje"
          }
        ],
        items: []
      },
      orderElements: {
        fields: [
          {
            key: "erpPrize.code",
            label: "Kod",
            sortable: true
          },
          {
            key: "erpPrize.name",
            label: "Nazwa",
            sortable: true
          },
          {
            key: "cost",
            label: "Koszt [pkt]",
            align: "end",
            sortable: true
          },
          {
            key: "quantity",
            label: "Ilość",
            align: "end",
            sortable: true
          }
        ],
        items: []
      },
      order: {
        fullNumber: "",
        date: "",
        status: "",
        erpUser: {},
        paymentForm: "",
        name: "",
        street: "",
        postal: "",
        locality: ""
      }
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getOrders() {
      ApiService.get("/orders").then(response => {
        this.orders.items = response.data;
        this.state = "loaded";
      });
    },

    getDetails(record) {
      this.orderElements.items = record.elements;
      this.order = record;
      this.$refs.orderDetailModal.show();
    },

    deleteOrder(row) {
      Swal.fire({
        title: "Czy napewno chcesz usunąć wpis?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009136",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/order/" + row.id).then(() => {
            this.getOrders();
          });
        }
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "POTWIERDZONE") return "table-success";
      if (item.status === "ZAIMPORTOWANE") return "table-success";
      if (item.status === "ZŁOŻONE") return "table-warning";
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Historia Zamówień" },
      { title: "" }
    ]);

    this.getOrders();
  },
  computed: {
    totalPointCost: function() {
      return this.orderElements.items.reduce(
        (previous, current) => previous + current.quantity * current.cost,
        0
      );
    }
  }
};
</script>
